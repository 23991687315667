import { Menu, Layout, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { auth, firestore, storage } from "../config/Firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import RolesEnum from "../config/RolesEnum";

const { Header } = Layout;

export default function PageHeader({ user: { user }, image, setImage }) {
  //: { company, role, name }
  const { pathname } = useLocation();

  async function getImage() {
    if (image) return;
    try {
      const companySnap = await getDoc(
        doc(firestore, "company/" + user?.company?.id)
      );
      const companyData = companySnap.data();
      if (companyData && companyData?.image) {
        const refer = ref(storage, "company/" + companyData?.image);
        const urldownload = await getDownloadURL(refer);
        setImage(urldownload);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user?.company) getImage();
  }, [user?.company]);

  let menu = [
    {
      key: "entries",
      pathname: "/",
      label: <Link to="/">Entradas</Link>,
    },
  ];

  if (user?.role?.includes("admin_company") || user?.role?.includes("admin_global")) {
    menu.push({
      key: "user",
      pathname: "/user",
      label: <Link to="/user">Usuários</Link>,
    });
    menu.push({
      key: "report",
      pathname: "/report",
      label: <Link to="/report">Relatórios</Link>,
    });
  }

  if (user?.role?.includes("admin_global")) {
    menu.push({
      key: "company",
      pathname: "/company",
      label: <Link to="/company">Empresas</Link>,
    });
  }

  const defaults = [];
  const actived = menu.find((item) => pathname.includes(item.key));
  if (actived) defaults.push(actived.key);

  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "normal",
        display: "flex",
      }}
      className="no-print"
    >
      {user?.company && image && <img src={image} className="h-[70%] my-2 mr-2" />}

      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={defaults}
        items={menu}
        style={{
          flex: 1,
        }}
      />

      <Space>
        <div className="text-white">{user?.name}</div>
        <Button
          onClick={() => {
            auth.signOut();
          }}
          danger
          type="primary"
          size="small"
        >
          Sair
        </Button>
      </Space>
    </Header>
  );
}
