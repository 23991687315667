import { Tag } from "antd";

export default {
  pending: {
    key: "pending",
    name: "Pendente",
    tag: <Tag color="gold">Pendente</Tag>,
  },
  actived: {
    key: "actived",
    name: "Ativo",
    tag: <Tag color="success">Ativo</Tag>,
  },
  removed: {
    key: "removed",
    name: "Excluído",
    tag: <Tag color="error">Excluído</Tag>,
  },
  blocked: {
    key: "blocked",
    name: "Bloqueado",
    tag: <Tag color="warning">Bloqueado</Tag>,
  },
};
