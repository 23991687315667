import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  HomeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../config/Firebase";
import RolesEnum from "../config/RolesEnum";
import { createUserWithEmailAndPassword } from "firebase/auth";

export default function CompanyEditPage() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [
    {
      user: { company, role },
    },
  ] = useOutletContext();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    // Caso não seja Admin global, vai para a página inicial
    if (!role?.includes(RolesEnum.admin_global.key)) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (params.id) getCompany(params.id);
  }, [params]);

  async function getCompany(id) {
    const userSnap = await getDoc(doc(firestore, "company/" + id));
    if (userSnap?.data()) {
      const user = userSnap.data();
      form.setFieldValue("name", user.name);
    }
  }

  async function handleFinish(values) {
    setLoading(true);
    try {
      const data = {
        name: values.name,
        updatedAt: serverTimestamp(),
      };

      updateDoc(doc(firestore, "company/" + params.id), data);
      message.success(`Empresa '${data.name}' Atualizada!`);
      navigate("/company");
    } catch (error) {
      console.log(error?.message);
    }
    setLoading(false);
  }

  const isSuperAdmin = role?.includes(RolesEnum.admin_global.key);

  return (
    <>
      <Typography.Title level={3}>
        Editar Empresa <b>{form.getFieldValue("name")}</b>
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <Link to="/company">Empresas</Link>,
          },
          {
            title: "Editar " + form.getFieldValue("name"),
          },
        ]}
      />
      <Divider />

      <Form
        className="mt-10"
        size="large"
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={8}>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              rules={[{ required: true, message: "Obrigatório!" }]}
              name="name"
              label="Nome da Empresa"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
