import "./App.css";
import HomePage from "./pages/HomePage";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState, lazy } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, firestore } from "./config/Firebase";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import EntrancePage from "./pages/EntrancePage";
import ExitPage from "./pages/ExitPage";
import CompanyPage from "./pages/CompanyPage";
import PageHeader from "./components/PageHeader";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { Layout, Spin } from "antd";
import CompanyRegisterPage from "./pages/CompanyRegisterPage";
import UserPage from "./pages/UserPage";
import { LoadingOutlined } from "@ant-design/icons";
import UserRegisterPage from "./pages/UserRegisterPage";
import UserEditPage from "./pages/UserEditPage";
import CompanyEditPage from "./pages/CompanyEditPage";
import RegisterPage from "./pages/RegisterPage";
import ReportsPage from "./pages/ReportsPage";
import EntranceClientPage from "./pages/EntranceClientPage";

const { Content } = Layout;

const ProtectedRoute = ({}) => {
  const [user, setUser] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate();

  async function getUserFromEmail(values) {
    const q = query(
      collection(firestore, "user"),
      where("email", "==", values.email)
    );
    const docs = await getDocs(q);
    const list = [];
    docs.forEach((value) => list.push({ ...value.data(), id: value.id }));
    return list.length ? list[0] : null;
  }

  async function getKids(company) {
    try {
      let q = null;
      if (company?.id) {
        q = query(
          collection(firestore, "bartending"),
          where("company.id", "==", company.id)
        );
      } else {
        q = query(collection(firestore, "bartending"));
      }
      const docs = await getDocs(q);
      let childs = [];
      docs.forEach((doc) => {
        childs.push({ id: doc.id, ...doc.data() });
      });

      childs.sort(function (a, b) {
        var textA = a.child_name.toUpperCase();
        var textB = b.child_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      return childs;
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  useEffect(() => {
    const subcriber = onAuthStateChanged(auth, async (value) => {
      if (!value) {
        navigate("/login");
        return;
      }
      setLoading(true)
      const data = await getUserFromEmail(value)
      const kids = await getKids(data?.company)
      setLoading(false)
      setUser({ ...value, user: data, kids });
    });
    return subcriber;
  }, []);

  if (!user || loading) {
    return (
      <div className="flex flex-col items-center justify-center h-[100vh]">
        <Spin size="large" indicator={<LoadingOutlined />} />

        <p className="align-center mt-20 font-medium">
          Por favor aguarde! Estamos preparando o ambiente e carregando os dados.
        </p>
      </div>
    );
  }
  
  return (
    <Layout>
      <PageHeader user={user} image={image} setImage={setImage} />
      <Content
        style={{
          height: "100vh",
          padding: "22px 10px 10px 10px",
          background: "#fff",
        }}
      >
        <Outlet context={[user, setUser]} />
      </Content>
    </Layout>
  );
};

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/:url" element={<EntranceClientPage />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/entrance" element={<EntrancePage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="/company/new" element={<CompanyRegisterPage />} />
        <Route path="/company/edit/:id" element={<CompanyEditPage />} />
        <Route path="/user" element={<UserPage />} />
        <Route path="/user/new" element={<UserRegisterPage />} />
        <Route path="/user/edit/:id" element={<UserEditPage />} />
        <Route path="/report" element={<ReportsPage />} />
      </Route>
    </Routes>
  );
}

export default App;
