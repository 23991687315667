import {
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "../config/Firebase";
import { useNavigate } from "react-router-dom";
import StatusEnum from "../config/StatusEnum";
import { DownOutlined } from "@ant-design/icons";
import { BiEditAlt, BiTrash } from "react-icons/bi";

const { Content } = Layout;

export default function CompanyPage() {
  const [list, setList] = useState([]);
  const [all, setAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  async function getList() {
    setLoading(true);
    const q = query(
      collection(firestore, "company"),
      where("status", "!=", StatusEnum.removed.key)
    );
    const docs = await getDocs(q);
    let companies = [];
    docs.forEach((doc) => {
      companies.push({ id: doc.id, ...doc.data() });
    });

    setAll(companies);
    setLoading(false);

    filtering(form.getFieldsValue(), companies);
  }

  useEffect(() => {
    getList();
  }, []);

  function filtering(values, allFromAsync = null) {
    const alllist = allFromAsync ? allFromAsync : all;
    const filterList = alllist.filter((item) => {
      if (!values?.name) return true;
      const checkName = item?.name
        ?.toUpperCase()
        .includes(values?.name?.toUpperCase());

      return checkName;
    });
    setList(filterList);
  }

  async function changeStatus(record, status = StatusEnum.actived.key) {
    await updateDoc(doc(firestore, "company/" + record.id), {
      status,
    });
    message.success(`${record.name} ${StatusEnum[status].name}(a)`);
    getList();
  }

  return (
    <>
      <Typography.Title level={2}>
        Empresas{" "}
        <Space wrap className="my-2">
          <Button
            onClick={() => navigate("/company/new")}
            size="small"
            type="primary"
          >
            Nova Empresa
          </Button>
        </Space>
      </Typography.Title>
      <Divider />

      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          filtering(values);
        }}
      >
        <Row gutter={8} className="items-end">
          <Col>
            <Form.Item name="name" label="Nome da Empresa">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Pesquisar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        dataSource={list}
        columns={[
          {
            title: "Nome",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value, record) => {
              return (
                <Dropdown
                  menu={{
                    items: Object.values(StatusEnum)
                      .filter((item) => item.key !== StatusEnum.removed.key)
                      .map((item) => {
                        return {
                          key: item.key,
                          label: (
                            <Button
                              type="link"
                              onClick={() => {
                                changeStatus(record, item.key);
                              }}
                            >
                              {item.name}
                            </Button>
                          ),
                        };
                      }),
                  }}
                >
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Space>
                      {StatusEnum[value].tag}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              );
            },
          },
          {
            title: "Ações",
            dataIndex: "actions",
            key: "actions",
            render: (value, record) => {
              return (
                <Space>
                  <Tooltip title="Editar">
                    <Button
                      onClick={() => navigate("/company/edit/" + record.id)}
                      type="link"
                      icon={<BiEditAlt size={20} />}
                    />
                  </Tooltip>
                  <Popconfirm
                    title="Exclusão da empresa"
                    description={`Deseja confirmar a exclusão do ${record.name}?`}
                    onConfirm={() =>
                      changeStatus(record, StatusEnum.removed.key)
                    }
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Tooltip title="Excluir">
                      <Button type="link" icon={<BiTrash size={20} />} />
                    </Tooltip>
                  </Popconfirm>
                </Space>
              );
            },
          },
        ]}
        expandable={{
          expandedRowRender: (record) => <p></p>,
          rowExpandable: (record) => {},
        }}
        rowKey="id"
        locale={{
          emptyText: <Empty description="Nenhum registro" />,
        }}
      />
    </>
  );
}
