import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Typography,
  message,
  Modal,
  AutoComplete,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../config/Firebase";
import { differenceInCalendarYears } from "date-fns";
import { ModalBody, ModalTitle } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import { GrClearOption, GrSearch } from "react-icons/gr";
import { FaTimes } from "react-icons/fa";
import FormEntrance from "../components/FormEntrance";
import dayjs from "dayjs";

const isEmpty = (value) => {
  return !value || value === undefined || value === "" || value === null;
};

const renderItem = (_, query, isPre = false) => {
  return {
    key: _.id,
    value: _.child_name,
    label: (
      <div style={{ position: "relative" }}>
        <p>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[query]}
            autoEscape={true}
            textToHighlight={_.child_name}
          />
        </p>
        <small>
          <b>Responsaveis:</b>{" "}
          {_.parents
            .map((item) => {
              return !isEmpty(item?.cpf)
                ? item.name + " (" + item.cpf + ")"
                : item.name;
            })
            .join(", ")}
        </small>
      </div>
    ),
  };
};

const searchResult = (all, query) => {
  const pres = all.filter((_) => _?.pre);

  return [
    {
      label: "Pré Cadastro",
      options: pres.map((_) => renderItem(_, query, true)),
    },
    {
      label: "Cadastrados",
      options: all.filter((_) => !_?.pre).map((_) => renderItem(_, query)),
    },
  ];
};

export default function EntrancePage() {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [kids, setKids] = useState([]);
  const [loadingKids, setLoadingKids] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(null);
  const [allKids, setAllKids] = useState([]);

  const [form] = Form.useForm();
  const [
    {
      user: { company, uid, name, role },
      kids: allListKids,
    },
    setUser,
  ] = useOutletContext();

  const birthday = Form.useWatch("child_brithday", form);
  const another_parent = Form.useWatch("another_parent", form);
  const navigate = useNavigate();

  async function getKids() {
    let list = allListKids;
    setAllKids(list);

    const obj = {};
    list.map((item) => {
      if (!obj[item.child_name]) {
        obj[item.child_name] = {
          ...item,
          qtd: 1,
        };
      } else {
        obj[item.child_name].qtd++;
      }
    });
    setKids(Object.values(obj));
    // await getKidsPre(Object.values(obj), list)
  }

  async function getKidsPre(currkids, currlist) {
    const q = query(
      collection(firestore, "bartending_pre"),
      company ? where("company.id", "==", company.id) : null
    );
    const docs = await getDocs(q);
    let list = [];
    docs.forEach((doc) => {
      list.push({ id: doc.id, ...doc.data(), pre: true });
    });
    setAllKids([...currlist, ...list]);

    const obj = {};
    list.map((item) => {
      if (!obj[item.child_name]) {
        obj[item.child_name] = {
          ...item,
          qtd: 1,
        };
      } else {
        obj[item.child_name].qtd++;
      }
    });

    setKids([...currkids, ...Object.values(obj)]);
  }

  useEffect(() => {
    getKids();
  }, []);

  function handleFinish(values) {
    setLoading(true);
    try {
      const newBartending = doc(collection(firestore, "bartending"));

      if (!values.exit_alone) values.exit_alone = false;
      if (!values.another_parent) values.another_parent = false;
      if (!values.waiter) values.waiter = null;
      if (!values.child_brithday) values.age = getAge();

      for (let key in values) {
        if (values[key] === undefined) delete values[key];
      }

      values.parents = values.parents.map((item) => {
        for (let key in item) {
          if (item[key] === undefined) delete item[key];
        }
        return item;
      });

      const data = {
        ...values,
        date_entrance: serverTimestamp(),
        user: { uid, name },
        status: "inside",
      };

      if (company) data.company = company;
      else {
        const companyForm = companies.find(
          (item) => item.id === values.company
        );
        data.company = {
          id: companyForm.id,
          name: companyForm.name,
        };
      }

      setDoc(newBartending, data)
        .then(async () => {
          message.success("Entrada Registrada!");

          if (selected?.pre && selected.id) {
            await deleteDoc(doc(firestore, "bartending_pre/" + selected.id));
          }

          setUser((prev) => ({
            ...prev,
            kids: [
              ...allListKids,
              { ...data, date_entrance: dayjs(), id: newBartending.id },
            ],
          }));

          navigate("/");
        })
        .finally(() => setLoading(false));
    } catch (error) {
      message.error(
        "Erro ao tentar salvar! Contate o administrador do sistema"
      );
      console.error(error);
      setLoading(false);
    }
  }

  function getAge(print = false) {
    let age = "";
    if (form && birthday && !birthday.includes("_")) {
      const separate = birthday.split("/");
      const birth = new Date(separate[2], separate[1], separate[0]);
      age = differenceInCalendarYears(new Date(), birth);
    }

    if (print) return "(" + age + " anos)";
    return age;
  }

  const handleSearch = (value) => {
    const filter = kids.filter((item) => {
      const name = item.child_name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const compareName = value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return name.toLowerCase().includes(compareName.toLowerCase());
    });
    setOptions(value ? searchResult(filter, value) : []);
  };

  const onSelect = (_, record) => {
    const kid = allKids.find((item) => item.id === record.key);
    setSelected(kid);
  };

  const handleOk = () => {
    if (selected) {
      selected.child_brithday.replace(new RegExp("/", "g"), "");
      form.setFieldsValue(selected);
      form.setFieldValue("bar_table", "");
      form.setFieldValue("bartending_number", "");
      form.setFieldValue("waiter", "");
      setOpen(false);
    } else {
      message.error("Selecione uma criança primeiro");
    }
  };

  return (
    <>
      <Typography.Title level={3}>Cadastrar Entrada</Typography.Title>
      <Space size={24}>
        <Breadcrumb
          items={[
            {
              title: <Link to="/">Entradas</Link>,
            },
            {
              title: "Cadastrar Entrada",
            },
          ]}
        />
        <Button icon={<GrSearch />} onClick={() => setOpen(true)}>
          Buscar Criança
        </Button>
        <Button icon={<GrClearOption />} onClick={() => form.resetFields()}>
          Limpar Formulário
        </Button>
      </Space>

      <Divider />

      <FormEntrance
        form={form}
        callback={handleFinish}
        loading={loading}
        showBtn={true}
      />

      <Modal
        onCancel={() => setOpen(false)}
        cancelButtonProps={{ hidden: true }}
        open={open}
        centered
        okText={"Carregar dados"}
        onOk={handleOk}
      >
        <ModalTitle>
          <b style={{ fontSize: 20 }}>Pesquisar</b>
          <Divider />
        </ModalTitle>
        <ModalBody>
          <AutoComplete
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            style={{ width: "100%" }}
            onClear={() => {
              setSelected(null);
            }}
          >
            <Input
              style={{ width: "100%" }}
              size="large"
              placeholder="Nome da Criança"
              allowClear
              autoFocus
            />
          </AutoComplete>
        </ModalBody>
      </Modal>
    </>
  );
}
