import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Space,
  Typography,
  Select,
  Modal,
  Table,
  Empty,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import dayjs from "dayjs";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "@firebase/firestore";
import { firestore, storage } from "../config/Firebase";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import RolesEnum from "../config/RolesEnum";
import { ModalBody, ModalTitle } from "react-bootstrap";
import Highlighter from "react-highlight-words";
import {
  differenceInCalendarYears,
  differenceInHours,
  differenceInMinutes,
  formatDistanceStrict,
  formatDuration,
  intervalToDuration,
} from "date-fns";
import "./ReportPage.css";
import { getDownloadURL, ref } from "firebase/storage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      //position: "left",
      display: false,
    },
    title: {
      display: true,
      text: "",
    },
  },
};

dayjs.locale("pt-br");
const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY";

function randomInteger(max) {
  return Math.floor(Math.random() * (max + 1));
}

function randomRgbColor() {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return [r, g, b];
}

export default function ReportsPage() {
  const [form] = Form.useForm();
  const [list, setList] = useState([]);
  const [all, setAll] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [image, setImage] = useState(null);
  const [
    {
      user: { company, role },
      kids
    },
  ] = useOutletContext();

  const navigate = useNavigate();

  async function getCompanies() {
    setLoadingCompanies(true);
    const q = query(collection(firestore, "company"));
    const docs = await getDocs(q);
    let list = [];
    docs.forEach((doc) => {
      list.push({ id: doc.id, ...doc.data() });
    });
    setCompanies(list);
    setLoadingCompanies(false);
  }

  useEffect(() => {
    if (role?.includes(RolesEnum.admin_global.key)) getCompanies();
  }, []);

  async function getList() {
    filtering(form.getFieldsValue(), kids);
  }

  useEffect(() => {
    getList();
  }, []);

  function filtering(values, allFromAsync = null) {
    const alllist = allFromAsync ? allFromAsync : all;
    const filterList = alllist.filter((item) => {
      let checkInterval = true;
      const date = item.date_entrance;
      if (
        dayjs(values.interval[0]).format("DDMMYYYY") !==
        dayjs(values.interval[1]).format("DDMMYYYY")
      ) {
        const isSameBefore = dayjs(date.toDate()).isSame(values.interval[1]);
        const isBefore = dayjs(date.toDate()).isBefore(values.interval[1]);
        const isSameAfter = dayjs(date.toDate()).isSame(values.interval[0]);
        const isAfter = dayjs(date.toDate()).isAfter(values.interval[0]);
        checkInterval = (isSameBefore || isBefore) && (isSameAfter || isAfter);
      } else {
        checkInterval = dayjs(date.toDate()).isSame(values.interval[1], "day");
      }

      return checkInterval;
    });

    filterList.sort(function (a, b) {
      return a.date_entrance.toDate() - b.date_entrance.toDate();
    });

    setList(filterList);
    // mountChart(filterList);
  }

  function mountChart(filterList) {
    const obj = {};
    let filter = [];
    if (form.getFieldValue("company")) {
      filter = filterList.filter((item) => {
        return item.company.id === form.getFieldValue("company");
      });
    } else if (company?.id) {
      filter = filterList;
    }
    if (filter.length === 0) return;

    filter.map((item) => {
      const day = dayjs(item.date_entrance.toDate()).format("DD/MMM");
      const company = item.company.name;
      if (!obj[company]) {
        obj[company] = { [day]: 1 };
      } else {
        if (!obj[company][day]) obj[company][day] = 1;
        else obj[company][day]++;
      }
    });

    let labels = [];
    Object.keys(obj).map((item) => {
      labels = labels.concat(Object.keys(obj[item]));
    });

    let datasets = Object.keys(obj).map((item) => {
      const data = new Array(labels.length).fill(0);
      Object.keys(obj[item]).forEach((value) => {
        const idx = labels.indexOf(value);
        data[idx] = obj[item][value];
      });
      const color = randomRgbColor().join(", ");

      return {
        label: item,
        data,
        borderColor: `rgb(${color})`,
        backgroundColor: `rgba(${color}, 0.5)`,
      };
    });

    setData({
      labels,
      datasets,
    });
  }

  function getAge(child_brithday) {
    let age = "";
    if (child_brithday) {
      const separate = child_brithday.split("/");
      const birth = new Date(separate[2], separate[1], separate[0]);
      age = differenceInCalendarYears(new Date(), birth);
    }
    return age;
  }

  function getColumns() {
    let columns = [];

    if (role.includes(RolesEnum.admin_global.key)) {
      columns.push({
        title: "Empresa",
        dataIndex: "company",
        key: "company",
        render: (value) => {
          return (
            <Highlighter
              highlightClassName="highlight"
              searchWords={[form.getFieldValue("company")]}
              autoEscape={true}
              textToHighlight={value.name}
            />
          );
        },
      });
    }

    columns = columns.concat([
      {
        title: "Mesa",
        dataIndex: "bar_table",
        key: "bar_table",
        render: (value) => {
          return value;
        },
        responsive: ["md"],
        sorter: (a, b) => parseInt(a.bar_table) - parseInt(b.bar_table),
      },
      {
        title: "Nome",
        dataIndex: "child_name",
        key: "child_name",
        render: (value) => {
          return (
            <Highlighter
              highlightClassName="highlight"
              searchWords={[form.getFieldValue("child_name")]}
              autoEscape={true}
              textToHighlight={value}
            />
          );
        },
        sorter: (a, b) => {
          return a.name > b.name ? 1 : -1;
        },
      },
      {
        title: "Idade",
        dataIndex: "age",
        key: "age",
        responsive: ["lg"],
        sorter: (a, b) => {
          if (!a?.child_brithday || !b?.child_brithday) return false;
          return (
            parseInt(getAge(a?.child_brithday)) -
            parseInt(getAge(b?.child_brithday))
          );
        },
        render: (value, record) => {
          console.log(record.child_name, record.child_brithday)
          return record.child_brithday ? getAge(record.child_brithday) : "-";
        },
      },
      {
        title: "Data de Entrada",
        dataIndex: "date_entrance",
        key: "date_entrance",
        render: (value) => {
          return dayjs(value.toDate()).format("DD/MM/YYYY");
        },
        responsive: ["lg"],
      },
      {
        title: "Hora de Entrada",
        dataIndex: "date_entrance",
        key: "date_entrance",
        render: (value) => {
          return dayjs(value.toDate()).format("HH:mm:ss");
        },
        responsive: ["md"],
      },
      {
        title: "Hora de Saída",
        dataIndex: "date_exit",
        key: "date_exit",
        render: (value) => {
          if (!value) return "-";
          return dayjs(value.toDate()).format("HH:mm:ss");
        },
        responsive: ["md"],
      },
      {
        title: "Permanência",
        dataIndex: "time",
        key: "time",
        render: (value, record) => {
          const result = formatDistanceStrict(
            record?.date_exit ? record?.date_exit?.toDate() : new Date(),
            record.date_entrance.toDate(),
            { locale: ptBR }
          );

          return result;
        },
        responsive: ["lg"],
      },
    ]);
    return columns;
  }

  async function getImage() {
    try {
      const companySnap = await getDoc(
        doc(firestore, "company/" + company?.id)
      );
      const companyData = companySnap.data();
      if (companyData && companyData?.image) {
        const refer = ref(storage, "company/" + companyData?.image);
        const urldownload = await getDownloadURL(refer);
        setImage(urldownload);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getImage() {
    try {
      const companySnap = await getDoc(
        doc(firestore, "company/" + company?.id)
      );
      const companyData = companySnap.data();
      if (companyData && companyData?.image) {
        const refer = ref(storage, "company/" + companyData?.image);
        const urldownload = await getDownloadURL(refer);
        setImage(urldownload);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const isSuperAdmin = role?.includes(RolesEnum.admin_global.key);

  return (
    <>
      <Typography.Title className="no-print" level={3}>
        Gráficos e Relatórios
      </Typography.Title>
      <Divider className="no-print" />

      {company && image && (
        <img src={image} className="h-[70%] my-2 mr-2 print-only" />
      )}

      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          filtering(values);
        }}
        initialValues={{
          interval: [dayjs().subtract(1, "month"), dayjs()],
        }}
      >
        <Row gutter={8} className="items-end">
          {isSuperAdmin && (
            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item
                rules={[{ required: true, message: "Obrigatório!" }]}
                name="company"
                label="Empresa"
              >
                <Select
                  showSearch
                  placeholder="Selecione a Empresa"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={companies.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </Form.Item>
            </Col>
          )}
          <Col>
            <Form.Item name="interval" label={"Período"}>
              <RangePicker
                format={dateFormat}
                placeholder={["Inicio", "Final"]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Space>
                <Button
                  className="no-print"
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Pesquisar
                </Button>

                <Button className="no-print" onClick={() => window.print()}>
                  Imprimir
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Space>
        <div style={{ color: "red" }}>
          <b>Total de crianças: </b>
          {list?.length}
        </div>
      </Space>

      <Table
        loading={loading}
        dataSource={list}
        pagination={false}
        columns={getColumns(true)}
        rowKey="id"
        locale={{
          emptyText: <Empty description="Nenhum registro" />,
          triggerDesc: "Do maior para o menor",
          triggerAsc: "Do menor para o maior",
          cancelSort: "Cancelar Ordenação",
        }}
      />
    </>
  );
}
