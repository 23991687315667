import { Button, Form, Input, Steps, message } from "antd";
import React, { useState } from "react";
import { auth, firestore } from "../config/Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import StatusEnum from "../config/StatusEnum";
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";

const steps = [
  {
    key: 0,
    title: "Validar",
    icon: <UserOutlined />,
  },
  {
    key: 1,
    title: "Acessar",
    icon: <SolutionOutlined />,
  },
];

export default function RegisterPage() {
  const [formEmail] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [openNewPassword, setOpenNewPassword] = useState(false);
  const [userRef, setUserRef] = useState(null);
  const [current, setCurrent] = useState(0);

  let navigate = useNavigate();

  async function getFirstAccessUser(values) {
    const q = query(
      collection(firestore, "user"),
      where("email", "==", values.email),
      where("status", "==", "pending")
    );
    const docs = await getDocs(q);
    const list = [];
    docs.forEach((value) => list.push({ ...value.data(), id: value.id }));
    return list.length ? list[0] : null;
  }

  const createCredendials = async (values) => {
    setLoadingPassword(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        userRef.email,
        values.password
      );
      if (userCredential.user) {
        const uid = userCredential.user.uid;
        await updateDoc(doc(firestore, "user/" + userRef.id), {
          status: "actived",
          uid,
        });
        navigate("/");
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoadingPassword(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const firstAccessUser = await getFirstAccessUser(values);

      if (!firstAccessUser) {
        message.warning(`${values.email} não está habilitado para acesso.`);
      } else {
        setUserRef(firstAccessUser);
        setCurrent(current + 1);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="mt-20 ml-auto mr-auto max-w-xs p-5">
      <Steps current={current} items={steps} />
      {current === 0 ? (
        <Form
          className="mt-5"
          form={formEmail}
          name="checkEmail"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Preencha o email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item className="flex justify-center">
            <Button loading={loading} type="primary" htmlType="submit">
              {loading ? "Validando..." : "Validar Email"}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          className="mt-5"
          form={formPassword}
          name="createCredentials"
          layout="vertical"
          onFinish={createCredendials}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Nova Senha"
            name="password"
            rules={[
              {
                required: true,
                message: "Preencha a senha!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item className="flex justify-center">
            <Button loading={loading} type="primary" htmlType="submit">
              {loading ? "Salvando..." : "Salvar e entrar"}
            </Button>
          </Form.Item>
        </Form>
      )}
      <div className="flex justify-center">
        <Button onClick={() => navigate("/login")} type="link">
          Ir para Login
        </Button>
      </div>
    </div>
  );
}
