import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Select,
  AutoComplete,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { FaBirthdayCake } from "react-icons/fa";
import dayjs from "dayjs";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate, useOutletContext } from "react-router-dom";
import { firestore } from "../config/Firebase";
import InputMask from "react-input-mask";
import { differenceInCalendarYears } from "date-fns";
import RolesEnum from "../config/RolesEnum";
import { v4 as uuidv4 } from "uuid";

import {
  HomeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import Highlighter from "react-highlight-words";

function validarCPF(inputCPF) {
  var soma = 0;
  var resto;

  if (inputCPF == "00000000000") return false;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(inputCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(inputCPF.substring(10, 11))) return false;
  return true;
}

export default function FormEntrance({
  form,
  callback: handleFinish = () => {},
  showBtn = false,
  object = {},
  loading = false,
  fromParent = false,
}) {
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [options, setOptions] = useState([]);
  const [parents, setParents] = useState([]);

  const birthday = Form.useWatch("child_brithday", form);
  const another_parent = Form.useWatch("another_parent", form);
  const navigate = useNavigate();
  const outlet = useOutletContext();
  const user = outlet?.length ? outlet[0]?.user : null;
  const kids = outlet?.length ? outlet[0]?.kids : null;

  function getAge(print = false) {
    let age = "";
    if (form && birthday && !birthday.includes("_")) {
      const separate = birthday.split("/");
      const birth = new Date(separate[2], separate[1], separate[0]);
      age = differenceInCalendarYears(new Date(), birth);
    }
    if (print) return "(" + age + " anos)";
    return age;
  }

  function isBirth() {
    if (form && birthday) {
      const separate = birthday.split("/");
      const day =
        dayjs().date() >= 10 ? "" + dayjs().date() : "0" + dayjs().date();
      const month =
        dayjs().month() >= 10
          ? "" + (dayjs().month() + 1)
          : "0" + (dayjs().month() + 1);
      return day === separate[0] && month === separate[1];
    }
  }

  async function getCompanies() {
    setLoadingCompanies(true);
    const q = query(collection(firestore, "company"));
    const docs = await getDocs(q);
    let list = [];
    docs.forEach((doc) => {
      list.push({ id: doc.id, ...doc.data() });
    });
    setCompanies(list);
    setLoadingCompanies(false);
  }

  useEffect(() => {
    if (user?.role?.includes(RolesEnum.admin_global.key)) getCompanies();
    getParents();
  }, []);

  async function getParents() {
    let list = kids;

    if (user?.company) {
      list = kids.filter((item) => item?.company?.id === user?.company?.id);
    }

    let listParents = [];
    list.map((item) => {
      listParents = listParents.concat([
        ...item.parents.map((p, idx) => ({ ...p, key: uuidv4() })),
      ]);
    });
    setParents(listParents);
  }

  useEffect(() => {
    if (object) {
      form.setFieldsValue(object);

      if (object.company) {
        //console.log(object.company)
      }
    }
  }, [object]);

  const isSuperAdmin = user?.role?.includes(RolesEnum.admin_global.key);

  const searchResult = (all, query) => {
    return all.map((_) => {
      return {
        key: _.key,
        value: _.name,
        label: (
          <div>
            <p>
              <Highlighter
                highlightClassName="highlight"
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={_.name}
              />
            </p>
            <small>
              {_.cpf ? (
                <>
                  <b>CPF:</b> {_.cpf} <br />
                </>
              ) : (
                ""
              )}
              {_.rg ? (
                <>
                  <b>RG:</b> ${_.rg} <br />
                </>
              ) : (
                ""
              )}
              {_.cellphone ? (
                <>
                  <b>Celular:</b> ${_.cellphone} <br />
                </>
              ) : (
                ""
              )}
            </small>
          </div>
        ),
      };
    });
  };

  return (
    <Form
      className="mt-10"
      size="large"
      form={form}
      layout="vertical"
      onFinish={handleFinish}
    >
      <Row gutter={8}>
        {isSuperAdmin && (
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Form.Item
              rules={[{ required: true, message: "Obrigatório!" }]}
              name="company"
              label="Empresa"
            >
              <Select
                showSearch
                placeholder="Selecione a Empresa"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={companies.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Form.Item
            rules={[{ required: true, message: "Obrigatório!" }]}
            name="child_name"
            label="Nome da Criança"
          >
            <Input />
          </Form.Item>
        </Col>

        <Col>
          <Space>
            <Form.Item
              name="child_brithday"
              label={`Data de Nascimento ${getAge(true)}`}
              rules={
                fromParent ? [{ required: true, message: "Obrigatório!" }] : []
              }
            >
              <InputMask mask="99/99/9999">
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </Form.Item>
            {isBirth() && (
              <FaBirthdayCake color="red" size={30} className="mt-4" />
            )}
          </Space>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col lg={12}>
          <Form.Item name="exit_alone" valuePropName="checked">
            <Checkbox>Pode entrar e sair da brinquedoteca sozinho</Checkbox>
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item name="another_parent" valuePropName="checked">
            <Checkbox>
              Outro responsável pode buscar a criança na saída
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.List
        name="parents"
        rules={[
          {
            validator: async (_, names) => {
              const limit = another_parent ? 2 : 1;
              if (!names || names.length < limit) {
                return Promise.reject(
                  new Error(`Pelo menos ${limit} responsável`)
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                className="border p-2 pb-0 pt-6 relative my-2 rounded overflow-hidden "
              >
                <Row gutter={8}>
                  <Col>
                    <Form.Item
                      {...restField}
                      rules={[{ required: true, message: "Obrigatório!" }]}
                      name={[name, "name"]}
                    >
                      {!fromParent ? (
                        <AutoComplete
                          options={options}
                          onSelect={(value, record) => {
                            const fields = form.getFieldValue("parents");
                            const p = parents.find(
                              (item) => item.key === record.key
                            );
                            if (fields[key]) {
                              Object.assign(fields[key], p);
                              form.setFieldValue("parents", fields);
                            }
                          }}
                          onSearch={(value) => {
                            const filter = parents.filter((item) => {
                              const name = item.name
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "");
                              const compareName = value
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "");
                              return name
                                .toLowerCase()
                                .includes(compareName.toLowerCase());
                            });
                            setOptions(
                              value ? searchResult(filter, value) : []
                            );
                          }}
                          style={{ width: "100%" }}
                        >
                          <Input placeholder="Nome" />
                        </AutoComplete>
                      ) : (
                        <Input placeholder="Nome" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      name={[name, "rg"]}
                      rules={
                        fromParent
                          ? [{ required: true, message: "Obrigatório!" }]
                          : []
                      }
                    >
                      <Input placeholder="RG" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        // { required: true, message: "Obrigatório!" },
                        fromParent
                          ? { required: true, message: "Obrigatório!" }
                          : {},
                        {
                          validator: (_, value) => {
                            if (!value || value === "___.___.___-__")
                              return Promise.resolve();

                            value = value.replace(/\./g, "");
                            value = value.replace("-", "");
                            if (validarCPF(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject("CPF Inválido");
                            }
                          },
                        },
                      ]}
                      name={[name, "cpf"]}
                    >
                      <MaskedInput
                        placeholder="CPF"
                        size="large"
                        mask={"000.000.000-00"}
                      />
                    </Form.Item>
                  </Col>
                  <MinusCircleOutlined
                    className="absolute right-2 top-2"
                    onClick={() => remove(name)}
                  />
                </Row>
                <Row gutter={8}>
                  <Col>
                    <Form.Item
                      {...restField}
                      // rules={[{ required: true, message: "Obrigatório!" }]}
                      name={[name, "address"]}
                      rules={
                        fromParent
                          ? [{ required: true, message: "Obrigatório!" }]
                          : []
                      }
                    >
                      <Input placeholder="Endereço" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      {...restField}
                      rules={
                        fromParent
                          ? [{ required: true, message: "Obrigatório!" }]
                          : []
                      }
                      name={[name, "cellphone"]}
                    >
                      <MaskedInput
                        placeholder="Celular"
                        size="large"
                        mask={"(00)00000-0000"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            {fields.length < 2 && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Adicionar Responsável
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      {!fromParent && (
        <Row gutter={8}>
          <Col>
            <Form.Item
              // rules={[{ required: true, message: "Obrigatório!" }]}
              name="bartending_number"
              label="Venda"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              // rules={[{ required: true, message: "Obrigatório!" }]}
              name="bar_table"
              label="Mesa"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="waiter" label="Garçom">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      {fromParent && (
        <p>
          *Ao clicar no botão Salvar você se responsabiliza pelas veracidade das
          informações aqui contidas.
          <br />
          <br />
        </p>
      )}

      {showBtn && (
        <Form.Item>
          <Space>
            <Button type="primary" onClick={() => navigate(-1)} danger>
              Cancelar
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Space>
        </Form.Item>
      )}
    </Form>
  );
}
