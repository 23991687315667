import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Typography,
  message,
  Space,
  Layout,
  Modal,
  Row,
  Col,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ModalBody, ModalTitle } from "react-bootstrap";

import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore, storage } from "../config/Firebase";
import { differenceInCalendarYears } from "date-fns";
import { GrClearOption, GrSearch } from "react-icons/gr";
import { AiFillCheckCircle } from "react-icons/ai";
import FormEntrance from "../components/FormEntrance";
import { getDownloadURL, ref } from "firebase/storage";

const { Content } = Layout;

export default function EntranceClientPage() {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [company, setCompany] = useState(null);
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [form] = Form.useForm();

  const birthday = Form.useWatch("child_brithday", form);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.url) {
      setup(params.url);
    }
  }, []);

  async function setup(url) {
    setLoading(true);
    const q = query(collection(firestore, "company"));
    const docs = await getDocs(q);
    let comp = null;
    try {
      docs.forEach(async (doc) => {
        if (doc.data().url === url) {
          comp = { id: doc.id, ...doc.data() };
          if (comp && comp?.image) {
            const refer = ref(storage, "company/" + comp?.image);
            const urldownload = await getDownloadURL(refer);
            setImage(urldownload);
          }
        }
      });
      setCompany(comp);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  function handleFinish(values) {
    setLoading(true);
    try {
      if (company.term && !accepted) {
        message.error("Você precisa aceitar os temos de uso.");
        setLoading(false);
        return;
      }

      const newBartending = doc(collection(firestore, "bartending_pre"));

      if (!values.exit_alone) values.exit_alone = false;
      if (!values.another_parent) values.another_parent = false;
      if (!values.waiter) values.waiter = null;
      if (!values.child_brithday) values.age = getAge();

      for (let key in values) {
        if (values[key] === undefined) delete values[key];
      }

      values.parents = values.parents.map((item) => {
        for (let key in item) {
          if (item[key] === undefined) delete item[key];
        }
        return item;
      });

      const data = {
        ...values,
        date_entrance: serverTimestamp(),
        status: "inside",
      };

      if (company) data.company = company;
      else {
        const companyForm = companies.find(
          (item) => item.id === values.company
        );
        data.company = {
          id: companyForm.id,
          name: companyForm.name,
        };
      }
      setDoc(newBartending, data)
        .then(() => {
          message.success("Cadastro Realizado!");

          const modal = Modal.confirm({
            okText: "Sim",
            cancelText: "Nâo",
            title: "Cadastrar nova criança?",
            content: "Deseja usar os mesmos dados dos responsáveis?",
            onOk: () => {
              form.setFieldsValue({
                child_name: "",
                child_brithday: "",
              });
            },
            onCancel: ()=> {
              form.resetFields();
              setAccepted(false)
            }
          });

          //form.resetFields();
          //navigate("/");
        })
        .finally(() => setLoading(false));
    } catch (error) {
      message.error(
        "Erro ao tentar salvar! Contate o administrador do sistema"
      );
      console.error(error);
      setLoading(false);
    }
  }

  function getAge(print = false) {
    let age = "";
    if (form && birthday && !birthday.includes("_")) {
      const separate = birthday.split("/");
      const birth = new Date(separate[2], separate[1], separate[0]);
      age = differenceInCalendarYears(new Date(), birth);
    }

    if (print) return "(" + age + " anos)";
    return age;
  }

  function handleOk() {
    setAccepted(true);
    setOpen(false);
  }

  if (!company) {
    return (
      <Layout>
        <Content
          style={{
            height: "100vh",
            padding: "22px 50px 10px 50px",
            background: "#fff",
          }}
        >
          Carregando...
        </Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Content
        style={{
          height: "100vh",
          padding: "22px 50px 10px 50px",
          background: "#fff",
        }}
      >
        {company && image && (
          <img src={image} className="h-[70px] mx-auto my-2" />
        )}

        <Typography.Title level={3} className="text-center">
          Cadastrar Criança
        </Typography.Title>

        <Divider />

        {company.term && (
          <Button
            icon={accepted ? <AiFillCheckCircle /> : ""}
            size="small"
            onClick={() => setOpen(true)}
            type="dashed"
          >
            Ler e Aceitar os Termos de Uso
          </Button>
        )}

        <FormEntrance
          form={form}
          callback={handleFinish}
          loading={loading}
          showBtn={true}
          fromParent={true}
        />

        <Modal
          onCancel={() => {
            setOpen(false);
          }}
          cancelText="Fechar"
          open={open}
          centered
          okText={"Aceitar Termo"}
          onOk={handleOk}
        >
          <ModalTitle>
            <b style={{ fontSize: 20 }}>Editar Entrada</b>
            <Divider />
          </ModalTitle>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: company?.term }} />
          </ModalBody>
        </Modal>
      </Content>
    </Layout>
  );
}
