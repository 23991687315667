import {
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../config/Firebase";
import { useNavigate, useOutletContext } from "react-router-dom";
import RolesEnum from "../config/RolesEnum";
import StatusEnum from "../config/StatusEnum";
import { BiEditAlt, BiTrash, BiBlock } from "react-icons/bi";
import { DownOutlined } from "@ant-design/icons";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { deleteUser, getAuth } from "firebase/auth";

const { Content } = Layout;

export default function UserPage() {
  const [list, setList] = useState([]);
  const [all, setAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [
    {
      user: { company, uid, role },
    },
  ] = useOutletContext();

  async function getList() {
    setLoading(true);

    let q = query(collection(firestore, "user"));
    if (company?.id) {
      console.log(company.id);

      q = query(
        collection(firestore, "user"),
        where("company.id", "==", company.id)
      );
    }
    const docs = await getDocs(q);
    let users = [];
    docs.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });

    console.log(users);

    setAll(users);
    setLoading(false);

    filtering(form.getFieldsValue(), users);
  }

  useEffect(() => {
    getList();
  }, []);

  function filtering(values, allFromAsync = null) {
    const alllist = allFromAsync ? allFromAsync : all;
    const filterList = alllist.filter((item) => {
      if (!values?.name) return true;
      const checkName = item?.name
        ?.toUpperCase()
        .includes(values?.name?.toUpperCase());

      return checkName;
    });
    setList(filterList);
  }

  async function changeStatus(record, status = StatusEnum.actived.key) {
    await updateDoc(doc(firestore, "user/" + record.id), {
      status,
    });
    message.success(`Usuário ${StatusEnum[status].name}`);
    getList();
  }

  function getColumns() {
    let cols = [
      {
        title: "Nome",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (value, record) => {
          if (record.uid === uid || record.status === StatusEnum.pending.key)
            return StatusEnum[value].tag;
          return (
            <Dropdown
              menu={{
                items: Object.values(StatusEnum)
                  .filter(
                    (item) =>
                      item.key !== StatusEnum.removed.key &&
                      item.key !== StatusEnum.pending.key
                  )
                  .map((item) => {
                    return {
                      key: item.key,
                      label: (
                        <Button
                          type="link"
                          onClick={() => {
                            changeStatus(record, item.key);
                          }}
                        >
                          {item.name}
                        </Button>
                      ),
                    };
                  }),
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space>
                  {StatusEnum[value].tag}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          );
        },
      },
    ];

    if (role.includes(RolesEnum.admin_global.key)) {
      cols.push({
        title: "Empresa",
        dataIndex: "company",
        key: "company",
        render: (value) => {
          return value?.name || "-";
        },
      });
    }

    cols = cols.concat([
      {
        title: "Permissões",
        dataIndex: "role",
        key: "role",
        render: (value) => {
          return value
            ? value.map((item) => RolesEnum[item].name).join(", ")
            : "-";
        },
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        render: (value, record) => {
          return (
            <Space>
              <Tooltip title="Editar">
                <Button
                  onClick={() => navigate("/user/edit/" + record.id)}
                  type="link"
                  icon={<BiEditAlt size={20} />}
                />
              </Tooltip>
              {/* <Popconfirm
              title="Exclusão de usuário"
              description={`Deseja confirmar a exclusão do ${record.name}?`}
              onConfirm={() =>
                changeStatus(record, StatusEnum.removed.key)
              }
              okText="Sim"
              cancelText="Não"
            >
              <Tooltip title="Excluir">
                <Button type="link" icon={<BiTrash size={20} />} />
              </Tooltip>
            </Popconfirm> */}
            </Space>
          );
        },
      },
    ]);
    return cols;
  }

  return (
    <>
      <Typography.Title level={2}>
        Usuários{" "}
        <Space wrap className="my-2">
          <Button
            onClick={() => navigate("/user/new")}
            size="small"
            type="primary"
          >
            Novo Usuário
          </Button>
        </Space>
      </Typography.Title>
      <Divider />

      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          filtering(values);
        }}
      >
        <Row gutter={8} className="items-end">
          <Col>
            <Form.Item name="name" label="Nome do Usuário">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Pesquisar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        dataSource={list}
        columns={getColumns()}
        expandable={{
          expandedRowRender: (record) => <p></p>,
          rowExpandable: (record) => {},
        }}
        rowKey="id"
        locale={{
          emptyText: <Empty description="Nenhum registro" />,
        }}
      />
    </>
  );
}
