import { Button, Form, Input, message, Divider } from "antd";
import React, { useState } from "react";
import { auth, firestore } from "../config/Firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import StatusEnum from "../config/StatusEnum";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  async function getUserAccess(values) {
    const q = query(
      collection(firestore, "user"),
      where("email", "==", values.email)
    );
    const docs = await getDocs(q);
    const list = [];
    docs.forEach((value) => list.push({ ...value.data(), id: value.id }));
    return list.length ? list[0] : null;
  }

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const signin = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      if (signin.user) {
        const dataUser = await getUserAccess(signin.user);
        if (dataUser.status !== StatusEnum.actived.key) {
          message.error("Usuário sem acesso");
          setLoading(false);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.message.includes("auth/missing-password")) {
        message.error("Password não preenchido");
      } else if (error.message.includes("auth/user-not-found")) {
        message.error("Usuário não existe");
      } else if (error.message.includes("auth/wrong-password")) {
        message.error("Email ou senha incorretos");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="mt-20 ml-auto mr-auto max-w-xs p-5">
      <div
        style={{
          backgroundImage: "url('brinquedotec_logo.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "center",
          width: "100%",
          height: 100,
        }}
      ></div>

      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Preencha o email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          rules={[
            {
              required: true,
              message: "Preencha a senha!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className="flex justify-center">
          <Button loading={loading} type="primary" htmlType="submit">
            {loading ? "Autenticando..." : "Entrar"}
          </Button>
        </Form.Item>
        <Divider />

        <div className="flex justify-center">
          <Button onClick={() => navigate("/register")} type="link">
            Primeiro Acesso
          </Button>
        </div>
      </Form>
    </div>
  );
}
