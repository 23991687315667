import {
  Badge,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { firestore } from "../config/Firebase";
import dayjs from "dayjs";
import { BiTimer } from "react-icons/bi";
import { GoSignIn, GoSignOut } from "react-icons/go";
import { FiEdit } from "react-icons/fi";
import Highlighter from "react-highlight-words";
import { differenceInCalendarYears, formatDistanceStrict } from "date-fns";
import { ptBR } from "date-fns/locale";
import RolesEnum from "../config/RolesEnum";
import "./DashboardPage.css";
import { ModalBody, ModalTitle } from "react-bootstrap";
import FormEntrance from "../components/FormEntrance";

dayjs.locale("pt-br");

const { Content } = Layout;
const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY";

export default function DashboardPage() {
  const [interval, setInterval] = useState("Dia");
  const [list, setList] = useState([]);
  const [all, setAll] = useState([]);
  const [open, setOpen] = useState(false);
  const [objectToEdit, setObjectToEdit] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [form] = Form.useForm();
  const [formedit] = Form.useForm();
  const navigate = useNavigate();

  const [{ user, kids }, setUser] = useOutletContext();

  async function getList() {
    filtering(form.getFieldsValue(), kids);
  }

  useEffect(() => {
    getList();
  }, [form, kids]);

  async function saveOut(record, status = "outside") {
    setLoadingSave(true);
    try {
      const data = { status };

      if (status === "outside") data.date_exit = serverTimestamp();

      await updateDoc(doc(firestore, "bartending", record.id), data);

      const tmpKids = kids.map((item) => {
        if (item.id === record.id) {
          const localObj = { ...item, status };
          if (status === "outside") localObj.date_exit = dayjs();
          return localObj;
        }
        return item;
      });

      setUser((prev) => ({ ...prev, kids: tmpKids }));
      getList();
    } catch (error) {
      console.log(error);
    }
    setLoadingSave(false);
  }

  function filtering(values, allFromAsync = null) {
    const alllist = allFromAsync ? allFromAsync : kids;
    const filterList = alllist.filter((item) => {
      let checkInterval = true;
      const date = item.date_entrance;
      if (values.interval && interval === "Período" && date) {
        const isSameBefore = dayjs(date.toDate()).isSame(values.interval[1]);
        const isBefore = dayjs(date.toDate()).isBefore(values.interval[1]);
        const isSameAfter = dayjs(date.toDate()).isSame(values.interval[0]);
        const isAfter = dayjs(date.toDate()).isAfter(isSameAfter);
        checkInterval = (isSameBefore || isBefore) && (isSameAfter || isAfter);
      } else if (values.interval && interval === "Dia" && date) {
        checkInterval = dayjs(date.toDate()).isSame(values.interval, "day");
      }

      let checkName = true;

      if (values.child_name) {
        checkName = item?.child_name
          ?.toUpperCase()
          .includes(values?.child_name?.toUpperCase());
      }

      let bar_table = true;
      if (values.bar_table) {
        bar_table = item?.bar_table.includes(values?.bar_table);
      }

      return checkInterval && checkName && bar_table;
    });

    setList(filterList);
  }

  function getDescription(record) {
    return (
      <>
        <Descriptions title="Informações">
          <Descriptions.Item label="Venda">
            {record.bartending_number}
          </Descriptions.Item>
          <Descriptions.Item label="Data Entrada">
            {dayjs(record.date_entrance.toDate()).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Hora Entrada">
            {dayjs(record.date_entrance.toDate()).format("HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label="Data Saída">
            {record.date_exit
              ? dayjs(record.date_exit.toDate()).format("DD/MM/YYYY")
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Hora Saída">
            {record.date_exit
              ? dayjs(record.date_exit.toDate()).format("HH:mm:ss")
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Pode sair sozinho?">
            {record.exit_alone ? "Sim" : "Não"}
          </Descriptions.Item>
          <Descriptions.Item label="Pode outro Responsável?">
            {record.another_parent ? "Sim" : "Não"}
          </Descriptions.Item>
          <Descriptions.Item label="Mesa">
            {record.bar_table ? record.bar_table : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Garçom">
            {record.waiter ? record.waiter : "-"}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Dados dos Responsáveis">
          {record.parents.map((parent, idx) => {
            return (
              <div key={"parent-" + idx}>
                <Descriptions.Item label="Nome">
                  {parent.name}
                </Descriptions.Item>
                <Descriptions.Item label="Celular">
                  {parent.cellphone}
                </Descriptions.Item>
                <Descriptions.Item label="CPF">{parent.cpf}</Descriptions.Item>
                <Descriptions.Item label="RG">{parent.rg}</Descriptions.Item>
                <Descriptions.Item span={3} label="Endereço">
                  {parent.address}
                </Descriptions.Item>
              </div>
            );
          })}
        </Descriptions>
      </>
    );
  }

  function getAge(child_brithday) {
    let age = "";
    if (child_brithday) {
      const separate = child_brithday.split("/");
      const birth = new Date(separate[2], separate[1], separate[0]);
      age = differenceInCalendarYears(new Date(), birth);
    }
    return age;
  }

  function getColumns(exited = false) {
    let columns = [];

    if (user?.role.includes(RolesEnum.admin_global.key)) {
      columns.push({
        title: "Empresa",
        dataIndex: "company",
        key: "company",
        render: (value) => {
          return (
            <Highlighter
              highlightClassName="highlight"
              searchWords={[form.getFieldValue("company")]}
              autoEscape={true}
              textToHighlight={value.name}
            />
          );
        },
      });
    }

    columns = columns.concat([
      {
        title: "Mesa",
        dataIndex: "bar_table",
        key: "bar_table",
        render: (value) => {
          return value;
        },
        responsive: ["md"],
        sorter: (a, b) => parseInt(a.bar_table) - parseInt(b.bar_table),
      },
      {
        title: "Nome",
        dataIndex: "child_name",
        key: "child_name",
        render: (value) => {
          return (
            <Highlighter
              highlightClassName="highlight"
              searchWords={[form.getFieldValue("child_name")]}
              autoEscape={true}
              textToHighlight={value}
            />
          );
        },
        sorter: (a, b) => {
          return a.name > b.name ? 1 : -1;
        },
      },
      {
        title: "Idade",
        dataIndex: "age",
        key: "age",
        responsive: ["lg"],
        sorter: (a, b) =>
          parseInt(getAge(a?.child_brithday)) -
          parseInt(getAge(b?.child_brithday)),
        render: (value, record) => {
          return record.child_brithday ? getAge(record.child_brithday) : "-";
        },
      },
      {
        title: "Data de Entrada",
        dataIndex: "date_entrance",
        key: "date_entrance",
        render: (value) => {
          return dayjs(value.toDate()).format("DD/MM/YYYY");
        },
        responsive: ["lg"],
      },
      {
        title: "Hora de Entrada",
        dataIndex: "date_entrance",
        key: "date_entrance",
        render: (value) => {
          return dayjs(value.toDate()).format("HH:mm:ss");
        },
        responsive: ["md"],
      },
      {
        title: "Hora de Saída",
        dataIndex: "date_exit",
        key: "date_exit",
        render: (value) => {
          if (!value) return "-";
          return dayjs(value.toDate()).format("HH:mm:ss");
        },
        responsive: ["md"],
      },
      {
        title: "Permanência",
        dataIndex: "time",
        key: "time",
        render: (value, record) => {
          const result = formatDistanceStrict(
            record?.date_exit ? record?.date_exit?.toDate() : new Date(),
            record.date_entrance.toDate(),
            { locale: ptBR }
          );

          return result;
        },
        responsive: ["lg"],
      },
    ]);

    columns.push({
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (value, record) => {
        return (
          <Space>
            <Tooltip title="Alterar">
              <Button
                loading={loadingUpdate}
                type="link"
                icon={<FiEdit size={20} />}
                onClick={() => {
                  setOpen(true);
                  setObjectToEdit(record);
                }}
              />
            </Tooltip>

            {!record.date_exit && record.status !== "waiting" && (
              <Popconfirm
                title="Registrar Saída Temporária"
                description="Deseja confirmar esta saída temporária?"
                onConfirm={() => saveOut(record, "waiting")}
                okText="Sim"
                cancelText="Não"
              >
                <Tooltip title="Registrar Saída Temporária">
                  <Button
                    loading={loadingSave}
                    type="link"
                    icon={<BiTimer size={20} />}
                  />
                </Tooltip>
              </Popconfirm>
            )}

            {!record.date_exit && record.status === "waiting" && (
              <Popconfirm
                title="Registrar Retorno"
                description="Deseja confirmar Retorno?"
                onConfirm={() => saveOut(record, "active")}
                okText="Sim"
                cancelText="Não"
              >
                <Tooltip title="Registrar Retorno">
                  <Button
                    loading={loadingSave}
                    type="link"
                    icon={<GoSignIn size={20} />}
                  />
                </Tooltip>
              </Popconfirm>
            )}

            {!record.date_exit && (
              <Popconfirm
                title="Registrar Saída"
                description="Deseja confirmar esta saída?"
                onConfirm={() => saveOut(record)}
                okText="Sim"
                cancelText="Não"
              >
                <Tooltip title="Registrar Saída">
                  <Button
                    loading={loadingSave}
                    type="link"
                    icon={<GoSignOut size={20} color="red" />}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </Space>
        );
      },
    });

    return columns;
  }

  return (
    <>
      <Typography.Title level={3}>
        Entradas{" "}
        <Space wrap className="my-2">
          <Button
            onClick={() => navigate("/entrance")}
            size="small"
            type="primary"
          >
            Registrar Entrada
          </Button>
        </Space>
      </Typography.Title>
      <Divider />

      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          filtering(values);
        }}
        initialValues={{
          interval:
            interval === "Dia"
              ? dayjs()
              : [dayjs().subtract(1, "month"), dayjs()],
        }}
      >
        <Row gutter={8} className="items-end">
          <Col>
            <Form.Item name="bar_table" label="Mesa">
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="child_name" label="Nome Criança">
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="interval"
              label={
                <>
                  <Radio.Group
                    size="small"
                    options={["Dia", "Período"]}
                    onChange={({ target: { value } }) => {
                      setInterval(value);
                      form.setFieldValue("interval", null);
                    }}
                    value={interval}
                  />
                </>
              }
            >
              {interval === "Dia" ? (
                <DatePicker format={dateFormat} placeholder="Dia" />
              ) : (
                <RangePicker
                  format={dateFormat}
                  placeholder={["Inicio", "Final"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Pesquisar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Tabs
        type="card"
        tabBarExtraContent={{
          right: (
            <Space wrap className="my-2">
              <Button
                onClick={() => navigate("/entrance")}
                size="middle"
                type="dashed"
              >
                Registrar Entrada
              </Button>
            </Space>
          ),
        }}
        items={[
          {
            label: (
              <>
                Na Brinquedoteca{" "}
                <Badge
                  count={
                    list.filter(
                      (item) => !item.date_exit && item.status !== "waiting"
                    ).length
                  }
                  showZero
                  color="#52c41a"
                  overflowCount={9999}
                />
              </>
            ),
            key: 1,
            children: (
              <Table
                loading={loading}
                dataSource={list.filter(
                  (item) => !item.date_exit && item.status !== "waiting"
                )}
                columns={getColumns()}
                expandable={{
                  expandedRowRender: (record) => getDescription(record),
                  rowExpandable: (record) => {
                    return true;
                  },
                }}
                rowKey="id"
                locale={{
                  emptyText: <Empty description="Nenhum registro" />,
                  triggerDesc: "Do maior para o menor",
                  triggerAsc: "Do menor para o maior",
                  cancelSort: "Cancelar Ordenação",
                }}
                rowClassName={(record) => {
                  return record.exit_alone ? "alone" : "";
                }}
              />
            ),
          },
          {
            label: (
              <>
                Saída Temporária{" "}
                <Badge
                  count={
                    list.filter(
                      (item) => !item.date_exit && item.status === "waiting"
                    ).length
                  }
                  color="#faad14"
                  showZero
                  overflowCount={9999}
                />
              </>
            ),
            key: 2,
            children: (
              <Table
                loading={loading}
                dataSource={list.filter(
                  (item) => !item.date_exit && item.status === "waiting"
                )}
                columns={getColumns()}
                expandable={{
                  expandedRowRender: (record) => getDescription(record),
                  rowExpandable: (record) => {
                    return true;
                  },
                }}
                rowKey="id"
                locale={{
                  emptyText: <Empty description="Nenhum registro" />,
                  triggerDesc: "Do maior para o menor",
                  triggerAsc: "Do menor para o maior",
                  cancelSort: "Cancelar Ordenação",
                }}
                rowClassName={(record) => {
                  //console.log(record)
                }}
              />
            ),
          },
          {
            label: (
              <>
                Já Saiu{" "}
                <Badge
                  count={list.filter((item) => !!item.date_exit).length}
                  showZero
                  overflowCount={9999}
                />
              </>
            ),
            key: 3,
            children: (
              <Table
                loading={loading}
                dataSource={list.filter((item) => !!item.date_exit)}
                columns={getColumns(true)}
                expandable={{
                  expandedRowRender: (record) => getDescription(record),
                  rowExpandable: (record) => {
                    return true;
                  },
                }}
                rowKey="id"
                locale={{
                  emptyText: <Empty description="Nenhum registro" />,
                  triggerDesc: "Do maior para o menor",
                  triggerAsc: "Do menor para o maior",
                  cancelSort: "Cancelar Ordenação",
                }}
              />
            ),
          },
        ]}
      />

      <Modal
        onCancel={() => {
          setOpen(false);
          setObjectToEdit(null);
        }}
        cancelText="Cancelar"
        open={open}
        centered
        okText={!loadingUpdate ? "Salvar" : "Salvando..."}
        onOk={() => {
          formedit.submit();
        }}
        okButtonProps={{ loading: loadingUpdate }}
      >
        <ModalTitle>
          <b style={{ fontSize: 20 }}>Editar Entrada</b>
          <Divider />
        </ModalTitle>
        <ModalBody>
          <FormEntrance
            form={formedit}
            callback={async (values) => {
              for (let key in values) {
                if (values[key] === undefined) delete values[key];
              }

              values.parents = values.parents.map((item) => {
                for (let key in item) {
                  if (item[key] === undefined) delete item[key];
                }
                return item;
              });

              setLoadingUpdate(true);
              await updateDoc(doc(firestore, "bartending/" + objectToEdit.id), {
                ...values,
              });
              message.success("Entrada atualizada!");
              const tmpKids = kids.map((item) => {
                if (item.id === objectToEdit.id) {
                  return { ...item, ...values };
                }
                return item;
              });

              setUser((prev) => ({ ...prev, kids: tmpKids }));
              getList();
              setOpen(false);
              setObjectToEdit(null);
              setLoadingUpdate(false);
            }}
            object={objectToEdit}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
