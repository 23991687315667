import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  HomeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { firestore } from "../config/Firebase";

export default function CompanyRegisterPage() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [
    {
      user: { company },
    },
  ] = useOutletContext();

  const navigate = useNavigate();

  function handleFinish(values) {
    setLoading(true);
    const newCompany = doc(collection(firestore, "company"));

    const data = {
      ...values,
      createdAt: serverTimestamp(),
      status: "actived",
    };

    setDoc(newCompany, data)
      .then(() => {
        message.success(`Empresa '${data.name}' Cadastrada!`);
        navigate("/company");
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Typography.Title level={3}>Cadastrar Nova Empresa</Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <Link to="/">Empresas</Link>,
          },
          {
            title: "Cadastrar Nova Empresa",
          },
        ]}
      />
      <Divider />

      <Form
        className="mt-10"
        size="large"
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={8}>
          <Col>
            <Form.Item
              rules={[{ required: true, message: "Obrigatório!" }]}
              name="name"
              label="Nome da Empresa"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
