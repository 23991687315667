import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";

import { LoadingOutlined } from "@ant-design/icons";

import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { MaskedInput } from "antd-mask-input";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, firestore } from "../config/Firebase";
import RolesEnum from "../config/RolesEnum";
import { createUserWithEmailAndPassword, updatePassword } from "firebase/auth";

export default function UserEditPage() {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [form] = Form.useForm();
  const [
    {
      user: { company, role },
    },
  ] = useOutletContext();

  const params = useParams();

  const formRole = Form.useWatch("role", form);

  const navigate = useNavigate();

  async function getCompanies() {
    setLoadingCompanies(true);
    const q = query(collection(firestore, "company"));
    const docs = await getDocs(q);
    let list = [];
    docs.forEach((doc) => {
      list.push({ id: doc.id, ...doc.data() });
    });
    setCompanies(list);
    setLoadingCompanies(false);
  }

  useEffect(() => {
    if (role?.includes(RolesEnum.admin_global.key)) getCompanies();
  }, []);

  useEffect(() => {
    if (params.id) getUser(params.id);
  }, [params]);

  async function getUser(id) {
    setLoadingPage(true);
    try {
      const userSnap = await getDoc(doc(firestore, "user/" + id));
      if (form && userSnap?.data()) {
        const user = userSnap.data();
        form.setFieldValue("name", user.name);
        form.setFieldValue("company", user?.company?.id);
        form.setFieldValue("role", user.role);
        form.setFieldValue("password", "");
        form.setFieldValue("email", user.email);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingPage(false);
  }

  function getCompany(values) {
    if (values?.company) {
      const company = companies.find((item) => item.id === values.company);
      return {
        id: company.id,
        name: company.name,
      };
    }
    return;
  }

  async function handleFinish(values) {
    setLoading(true);
    try {
      const data = {
        name: values.name,
        role: values.role,
        updatedAt: serverTimestamp(),
      };

      if (role.includes(RolesEnum.admin_global.key)) {
        data.company = getCompany(values);
      }
      if (values.role.includes(RolesEnum.admin_global.key)) {
        data.company = null;
      }

      updateDoc(doc(firestore, "user/" + params.id), data);
      message.success(`Usuário '${data.name}' Atualizado!`);
      navigate("/user");
    } catch (error) {
      if (error.message.includes("auth/email-already-in-use")) {
        message.error("Email já em uso");
      }
    }
    setLoading(false);
  }

  const isSuperAdmin = role?.includes(RolesEnum.admin_global.key);

  return (
    <>
      <Typography.Title level={3}>
        Editar Usuário <b>{form.getFieldValue("name")}</b>
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <Link to="/user">Usuários</Link>,
          },
          {
            title: "Editar " + form.getFieldValue("name"),
          },
        ]}
      />
      <Divider />

      <Spin size="large" spinning={loadingPage} indicator={<LoadingOutlined />}>
        <Form
          className="mt-10"
          size="large"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
        >
          <Row gutter={8}>
            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item
                rules={[{ required: true, message: "Obrigatório!" }]}
                name="name"
                label="Nome do usuário"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item
                rules={[
                  { required: true, message: "Obrigatório!" },
                  { type: "email", message: "Não é um email!" },
                ]}
                name="email"
                label="Email de Login"
              >
                <Input readOnly disabled />
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 8 }}>
              <Form.Item
                rules={[{ required: true, message: "Obrigatório!" }]}
                name="role"
                label="Permissões"
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Selecione as Permissões"
                  optionLabelProp="label"
                >
                  {Object.values(RolesEnum)
                    .filter((item) => {
                      if (!isSuperAdmin)
                        return item.key !== RolesEnum.admin_global.key;
                      return true;
                    })
                    .map((item) => (
                      <Select.Option
                        key={item.key}
                        value={item.key}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            {isSuperAdmin &&
              !formRole?.includes(RolesEnum.admin_global.key) && (
                <Col sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    rules={[{ required: true, message: "Obrigatório!" }]}
                    name="company"
                    label="Empresa"
                  >
                    <Select
                      showSearch
                      placeholder="Selecione a Empresa"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={companies.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              )}
          </Row>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}
